import React, { useState, useEffect, useCallback } from 'react';
import { useRouter } from 'next/router';
import { getRedirectUrl } from '@outdoorly/util';

import { useAuth } from '../providers/auth';
import { Login } from '../components/login';

const LoginPage: React.FC<unknown> = () => {
  const router = useRouter();
  const auth = useAuth();
  const [isRedirecting, setRedirecting] = useState(false);
  const redirectTo = getRedirectUrl(router.query.next_url);

  useEffect(() => {
    router.prefetch(redirectTo);
  }, [router, redirectTo]);

  const onLogin = useCallback(() => {
    setRedirecting(true);
    router.push(redirectTo);
  }, [router, setRedirecting, redirectTo]);

  useEffect(() => {
    if (auth.isAuthed) {
      onLogin();
    }
  }, [auth.isAuthed, onLogin]);

  return (
    <Login
      isLoading={isRedirecting}
      handleSignupClick={() => {
        router.push('/signup');
      }}
      handleForgotPassword={() => {
        router.push('/reset-password-request');
      }}
      handleCloseClick={() => {
        router.back();
      }}
    />
  );
};

export default LoginPage;
